import { ImageTypes } from '../../content';

const typeToStyle = {
    [ImageTypes.DEVICE]: ({ isTop }) => ({
        maxWidth: '70%',
        left: isTop ? '30%' : '0',
        top: isTop ? '-170px' : '70px',

        tabletLarge: {
            maxWidth: '16.25rem',
            left: isTop ? '30%' : '10%',
            top: isTop ? '-550px' : '70px',
        },
    }),
    [ImageTypes.IMAGE]: ({ isTop }) => ({
        maxWidth: '70%',
        left: isTop ? '20%' : 0,
        top: isTop ? 0 : '70px',

        tabletLarge: {
            maxWidth: '23.75rem',
            left: isTop ? '10%' : 0,
            top: isTop ? '-300px' : '70px',
        },

        desktop1200: {
            maxWidth: '26.25rem',
            left: isTop ? '20%' : 0,
        },
    }),
};

export const container = ({ type, isTop, isBottom }) => ({
    position: 'relative',
    ...typeToStyle[type]({ isTop, isBottom }),
});
