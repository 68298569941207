const image1 = 'contacts/intro/face-to-face.jpg';
const image2 = 'contacts/intro/face-to-face.jpg';

export const content = [
    {
        id: 1,
        title: 'contact.berlin.intro.column1.title',
        description: 'contact.berlin.intro.column1.description',
        image: image1,
    },
    {
        id: 2,
        title: 'contact.berlin.intro.column2.title',
        description: 'contact.berlin.intro.column2.description',
        image: image2,
    },
];
