import React from 'react';
import { PropTypes } from 'prop-types';
import Img from 'gatsby-image';

const IntroImage = ({ styles, title, fluid }) => (
    <div className={styles.image}>
        <Img alt={title} fluid={fluid} />
    </div>
);

IntroImage.propTypes = {
    styles: PropTypes.shape({
        image: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    fluid: PropTypes.shape().isRequired,
};

export default IntroImage;
