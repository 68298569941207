import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';

import { content } from './content';

import IntroColumn from './IntroColumn';

const { DynamicContainer, ContainerType } = UI;

const IntroSection = ({ rules }) => (
    <DynamicContainer extend={rules.extendContainer} type={ContainerType.FLEX} isFluid as={ContainerType.SECTION}>
        {content.map((item, index) => (
            <IntroColumn
                key={item.id}
                title={item.title}
                description={item.description}
                isLast={index === content.length - 1}
                src={item.image}
            />
        ))}
    </DynamicContainer>
);

IntroSection.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default IntroSection;
