import { Office } from 'modules/offices';

export const extendContainer = ({ office }) => {
    if (office === Office.BERLIN) {
        return {
            paddingBottom: 0,

            tabletLarge: {
                paddingTop: '12.3125rem',
                paddingBottom: '13.125rem',
                paddingLeft: '7.75rem',
            },
        };
    }
    return {
        paddingBottom: 0,

        tabletLarge: {
            paddingBottom: 0,
        },
    };
};

export const titleContainer = () => ({
    paddingBottom: 0,

    tabletLarge: {
        paddingBottom: 0,
    },
});

export const extendTitle = ({ theme: { colors } }) => ({
    color: colors.black,
    marginBottom: '5rem',
});
