export const extendContainer = ({ reversed }) => ({
    direction: reversed ? 'rtl' : 'ltr',
    overflow: 'visible',
    marginBottom: '5rem',

    tabletLarge: {
        gridTemplateColumns: `repeat(auto-fill, minmax(5.4%, 1fr))`,
    },

    desktop1200: {
        gridTemplateColumns: `repeat(auto-fill, minmax(6.3%, 1fr))`,
    },

    ':last-of-type': {
        marginBottom: 0,
    },

    '& figure': {
        direction: 'ltr',
    },

    '& div': {
        direction: 'ltr',
    },
});

export const parallaxContainer = () => ({
    overflow: 'visible',
    position: 'relative',
});

export const extendTitle = () => ({
    fontSize: '2.5rem',

    tablet: {
        width: '70%',
        fontSize: '3rem',
        textAlign: 'left',
    },
});

export const extendTextContainer = () => ({
    tabletLarge: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
});

export const link = ({ theme: { colors, transition } }) => ({
    color: colors.blue,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    borderBottom: `0.125rem solid ${colors.blue}`,

    transition,
    transitionProperty: 'color',

    marginTop: '2.5rem',
    display: 'inline-block',

    ':hover': {
        color: colors.fuchsia,
    },
});
