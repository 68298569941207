import React from 'react';
import { PropTypes } from 'prop-types';
import { InquirySection } from '../../inquiry';
import HeroSection from '../../hero-section';
import { FormattedMessage } from 'react-intl';
import * as UI from '../../ui';
import Config from '../../../config';
import { Office } from 'modules/offices';

import Address from '../components/Address';
import MapSection from '../components/MapSection';
import OfficeGallery from '../components/OfficeGallery';
import TestimonialsSection from '../components/TestimonialsSection';
import IntroSection from '../components/IntroSection';
import DeIntroSection from '../components/DeIntroSection';

import HeroImage from '../components/HeroImage';

const {
    env: { IS_DE_LANG_ENV },
} = Config;

const { SectionNavigation, sectionTitles, TitleTypes, LocalizedLink } = UI;

const ContactBerlin = ({ styles, rules, footerRef }) => {
    const office = Office.BERLIN;

    const heroRef = React.useRef();
    const galleryRef = React.useRef();
    const testimonialsRef = React.useRef();
    const contactRef = React.useRef();

    const sections = {
        hero: {
            ref: heroRef,
            title: sectionTitles.hero.general,
            id: 1,
        },
        gallery: {
            ref: galleryRef,
            title: sectionTitles.gallery.berlin,
            id: 2,
        },
        testimonials: {
            ref: testimonialsRef,
            title: sectionTitles.testimonials.berlin,
            id: 3,
        },
        contact: {
            ref: contactRef,
            title: sectionTitles.offices.berlin,
            id: 4,
        },
        footer: {
            ref: footerRef,
            title: sectionTitles.footer,
            id: 5,
        },
    };

    return (
        <>
            <HeroSection
                title={<FormattedMessage id="contact.berlin.hero.title" />}
                titleAs={TitleTypes.H1}
                sectionTitle={<FormattedMessage id="contact.berlin.section.title" />}
                description={
                    !IS_DE_LANG_ENV ? (
                        <FormattedMessage id="contact.berlin.hero.description" />
                    ) : (
                        <FormattedMessage
                            id="contact.berlin.hero.description"
                            values={{
                                linkto: chunks => (
                                    <LocalizedLink
                                        extend={{ link: rules.extendLink }}
                                        to={Config.routes['/app-development']}
                                    >
                                        <span className={styles.linkText}>{chunks}</span>
                                    </LocalizedLink>
                                ),
                            }}
                        />
                    )
                }
                showLogos
                ref={heroRef}
                customContent={
                    <HeroImage isBerlin src="contacts/heroes/berlin.png" alt="contact.berlin.hero.image.alt" />
                }
            />

            {!IS_DE_LANG_ENV ? <IntroSection /> : <DeIntroSection />}

            <OfficeGallery reference={galleryRef} office={office} />
            <TestimonialsSection reference={testimonialsRef} office={office} />
            <MapSection reference={contactRef} office={office} />
            <Address contactLinkEventId="contact-top" office={office} />
            <InquirySection />
            <SectionNavigation sections={sections} />
        </>
    );
};

ContactBerlin.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default ContactBerlin;
