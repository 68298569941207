import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';

import IntroItem from './IntroItem';

import { content } from './content';

const { DynamicContainer } = UI;

const DeIntroSection = ({ rules }) => (
    <DynamicContainer extend={{ container: rules.extendContainer }} isFluid>
        {content.map((item, index) => (
            <IntroItem content={item} key={item.title} reversed={index % 2 === 0} />
        ))}
    </DynamicContainer>
);

DeIntroSection.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default DeIntroSection;
