import audiLogo from '../../../../../static/images/testimonials/img_header_logo_audi.svg';

const testimonialImage1 = 'people/img_person_placeholder.jpg';

const testimonialImageDe1 = 'people/img_person_placeholder.jpg';

export const content = {
    prague: [
        {
            id: 1,
            src: testimonialImage1,
            alt: 'contact.prague.testimonials.image1.alt',
            testimonial: 'contact.prague.testimonials.testimonial1',
            name: 'contact.prague.testimonials.name1',
            company: 'contact.prague.testimonials.company1',
        },
        // {
        //     id: 2,
        //     src: testimonialImage2,
        //     alt: 'contact.prague.testimonials.image1.alt',
        //     testimonial: 'contact.prague.testimonials.testimonial2',
        //     name: 'contact.prague.testimonials.name2',
        //     company: 'contact.prague.testimonials.company2',
        // },
        // {
        //     id: 3,
        //     src: testimonialImage3,
        //     alt: 'contact.prague.testimonials.image1.alt',
        //     testimonial: 'contact.prague.testimonials.testimonial3',
        //     name: 'contact.prague.testimonials.name3',
        //     company: 'contact.prague.testimonials.company3',
        // },
    ],
    berlin: [
        {
            id: 1,
            src: testimonialImageDe1,
            alt: 'contact.berlin.testimonials.image1.alt',
            testimonial: 'contact.berlin.testimonials.testimonial1',
            name: 'contact.berlin.testimonials.name1',
            company: 'contact.berlin.testimonials.company1',
            logo: audiLogo,
        },
        // {
        //     id: 2,
        //     src: testimonialImageDe2,
        //     alt: 'contact.berlin.testimonials.image1.alt',
        //     testimonial: 'contact.berlin.testimonials.testimonial2',
        //     name: 'contact.berlin.testimonials.name2',
        //     company: 'contact.berlin.testimonials.company2',
        // },
        // {
        //     id: 3,
        //     src: testimonialImageDe3,
        //     alt: 'contact.berlin.testimonials.image1.alt',
        //     testimonial: 'contact.berlin.testimonials.testimonial3',
        //     name: 'contact.berlin.testimonials.name3',
        //     company: 'contact.berlin.testimonials.company3',
        // },
    ],
};
