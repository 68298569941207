export const extendLink = () => ({
    display: 'block',
    textDecoration: 'none',
    marginTop: '2.5rem',
});

export const linkText = ({ theme: { colors, transition } }) => ({
    color: colors.blue,
    fontSize: '1.25rem',
    display: 'inline-block',
    fontWeight: 'bold',

    borderBottom: `2px solid ${colors.blue}`,

    transition,
    transitionProperty: 'color',

    ':hover': {
        color: colors.fuchsia,
    },
});
