import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';
import { Parallax } from 'react-scroll-parallax';
import Config from '../../../../../config';

import Image from './Image';

const {
    Title,
    Text,
    DynamicContainer,
    ContainerType,
    TitleTypes,
    TextTypes,
    LocalizedLink,
    FormattedMessageWithLinks,
} = UI;

const IntroItem = ({ styles, rules, reversed, content: { title, description, imageBottom, imageTop, link } }) => {
    return (
        <DynamicContainer
            reversed={reversed}
            extend={{ container: rules.extendContainer }}
            as={ContainerType.SECTION}
            type={ContainerType.GRID}
            className={styles.container}
        >
            <DynamicContainer extend={{ container: rules.extendTextContainer }} columns={6}>
                <Title as={TitleTypes.H2} extend={{ title: rules.extendTitle }}>
                    <FormattedMessage id={title} />
                </Title>
                <Text type={TextTypes.LARGE}>
                    <FormattedMessageWithLinks id={description} />
                </Text>
                {link && (
                    <LocalizedLink extend={{ link: rules.link }} to={Config.routes['/references']}>
                        <FormattedMessage id={link} />
                    </LocalizedLink>
                )}
            </DynamicContainer>
            <DynamicContainer extend={{ container: rules.parallaxContainer }} columns={6}>
                <Parallax y={[-10, 10]} tagOuter="figure">
                    <Image src={imageBottom.src} alt={imageBottom.alt} isBottom type={imageBottom.type} />
                </Parallax>
                <Parallax y={[-20, 20]} tagOuter="figure">
                    <Image src={imageTop.src} alt={imageBottom.alt} isTop type={imageBottom.type} />
                </Parallax>
            </DynamicContainer>
        </DynamicContainer>
    );
};

IntroItem.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default IntroItem;
