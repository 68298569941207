import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Office } from 'modules/offices';

import * as UI from '../../../ui';
import Config from '../../../../config';

import { content } from './content';

const {
    env: { IS_DE_LANG_ENV },
} = Config;

const { Carousel, CarouselTypes, Text, TextTypes, DynamicContainer } = UI;

const TestimonialsSection = ({ office, reference, rules }) => {
    return (
        <>
            {IS_DE_LANG_ENV && office === Office.BERLIN && (
                <DynamicContainer extend={{ container: rules.titleContainer }} isFluid>
                    <Text extend={{ text: rules.extendTitle }} type={TextTypes.SECTION}>
                        <FormattedMessage id="contact.berlin.testimonials.title" />
                    </Text>
                </DynamicContainer>
            )}
            <Carousel
                office={office}
                reference={reference}
                content={content[office]}
                type={CarouselTypes.TESTIMONIALS}
                lazyLoaded
                extend={{ extendContainer: rules.extendContainer }}
            />
        </>
    );
};

TestimonialsSection.propTypes = {
    office: PropTypes.oneOf(Object.values(Office)).isRequired,
    reference: PropTypes.shape(),
};

TestimonialsSection.defaultProps = {
    reference: null,
};

export default TestimonialsSection;
