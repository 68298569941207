//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

import IntroImage from './IntroImage';

const { Title, TitleTypes, Text, TextTypes, FormattedMessageWithLinks } = UI;

const IntroColumn = ({ styles, title, description, src }) => {
    const images = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "contacts/intro" } }) {
                nodes {
                    base
                    relativePath
                    childImageSharp {
                        fluid(quality: 90, cropFocus: SOUTHWEST, maxWidth: 430, maxHeight: 285) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);

    const match = React.useMemo(() => images.allFile.nodes.find(({ relativePath }) => src === relativePath), [
        images,
        src,
    ]);

    const {
        childImageSharp: { fluid },
    } = match;

    const [hovered, setHovered] = React.useState(false);

    const handleHover = value => setHovered(value);

    return (
        <div
            role="tab"
            tabIndex="0"
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            className={styles.container}
        >
            <Title as={TitleTypes.H2} type={TitleTypes.H4}>
                <FormattedMessage id={title} />
            </Title>
            <Text as="p" type={TextTypes.LARGE}>
                <FormattedMessageWithLinks id={description} />
            </Text>
            <IntroImage hovered={hovered} title={title} fluid={fluid} />
        </div>
    );
};

IntroColumn.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
};

export default IntroColumn;
