export const image = ({ hovered }) => ({
    position: 'absolute',
    top: '-3rem',
    right: '3rem',
    width: '26.875rem',
    height: '17.8125rem',
    willChange: 'opacity',
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) opacity 0.3s',
    opacity: hovered ? 0.31 : 0,
    pointerEvents: 'none',
});
