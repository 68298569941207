export const extendContainer = () => ({
    paddingBottom: 0,

    tabletLarge: {
        paddingBottom: 0,
        marginBottom: -400,
    },

    desktopFullHD: {
        marginBottom: -400,
    },
});
