const ids = Array.from(Array(2).keys());

export const ImageTypes = {
    DEVICE: 'device',
    IMAGE: 'image',
};

const images = {
    0: {
        imageBottom: {
            src: `contacts/intro/contact_berlin_image_bottom_1.jpg`,
            alt: `contact.berlin.intro.de.imageBottom.alt.1`,
            type: ImageTypes.IMAGE,
        },
        imageTop: {
            src: `contacts/intro/contact_berlin_image_top_1.jpg`,
            alt: `contact.berlin.intro.de.imageTop.alt.1`,
            type: ImageTypes.IMAGE,
        },
    },
    1: {
        imageBottom: {
            src: `contacts/intro/contact_berlin_image_bottom_2.png`,
            alt: `contact.berlin.de.intro.imageBottom.alt.2`,
            type: ImageTypes.DEVICE,
        },
        imageTop: {
            src: `contacts/intro/contact_berlin_image_top_2.png`,
            alt: `contact.berlin.de.intro.imageTop.alt.2`,
            type: ImageTypes.DEVICE,
        },
    },
};

export const content = ids.map(id => ({
    title: `contact.berlin.de.intro.title.${id + 1}`,
    description: `contact.berlin.de.intro.description.${id + 1}`,
    ...images[id],
}));

content[1].link = `contact.berlin.de.intro.link.2`;
