import React from 'react';

import { Layout } from '../../modules/ui';
import ContactBerlin from '../../modules/contact/ContactBerlin';
import Seo from '../../components/SEO';
import * as Constants from '../../constants';

import { ParallaxProvider } from 'react-scroll-parallax';

const BerlinPage = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.BERLIN} />
            <Layout footerRef={footerRef}>
                <ParallaxProvider>
                    <ContactBerlin footerRef={footerRef} />
                </ParallaxProvider>
            </Layout>
        </>
    );
};

export default BerlinPage;
